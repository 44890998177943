<template>
  <div class="home">
    <el-container>
      <el-header class="flex-box header-box">
        <div class="flex-box">
          <!-- <img src="../assets/logo.png" class="img-logo"/> -->
          <span class="header-name">诺傲数科</span>
        </div>
        <el-menu
          class="el-menu-home"
          mode="horizontal"
          background-color="#001e4d"
          text-color="#fff"
          :ellipsis="ellipsis"
        >
          <el-menu-item v-for="menu in menuList" :key="menu.key" :index="menu.key">
            <a :href="menu.target">{{menu.label}}</a>
          </el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <div id="banner">
          <el-carousel trigger="click" height="400px">
            <el-carousel-item v-for="banner in bannerList" :key="banner.key">
              <img :src="banner.imgUrl" alt="banner" width="100%" height="400px">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div id="tecnology" class="technology-service">
          <div class="tab-title font-black">TECHNICAL SERVICES</div>
          <div class="tab-desc">/&nbsp;&nbsp;技术服务&nbsp;&nbsp;/</div>
          <div class="service-content">
            <div class="content-item flex-box">
              <data-display class="item-icon" theme="two-tone" size="64" :fill="['#333' ,'#0d52b3']" :strokeWidth="2" strokeLinecap="butt"/>
              <div class="item-content">
                <div class="flex-box item-content-title flex-start">
                  <span>数据库服务</span>
                  <i class="triangle"></i>
                </div>
                <div class="item-content-info">MySQL数据库 / MongoDB / Oracle数据库等</div>
              </div>
            </div>
            <div class="content-item flex-box">
              <new-computer class="item-icon" theme="two-tone" size="64" :fill="['#333' ,'#0d52b3']" :strokeWidth="2" strokeLinecap="butt"/>
              <div class="item-content">
                <div class="flex-box item-content-title flex-start">
                  <span>系统开发</span>
                  <i class="triangle"></i>
                </div>
                <div class="item-content-info">业务管理系统 / 考勤系统 / 门户网站等</div>
              </div>
            </div>
            <div class="content-item flex-box">
              <weixin-mini-app class="item-icon" theme="two-tone" size="64" :fill="['#333' ,'#0d52b3']" :strokeWidth="2" strokeLinecap="butt"/>
              <div class="item-content">
                <div class="flex-box item-content-title flex-start">
                  <span>小程序开发</span>
                  <i class="triangle"></i>
                </div>
                <div class="item-content-info">小程序开发 / 公众号开发 / 微信能力集成</div>
              </div>
            </div>
            <div class="content-item flex-box">
              <app-icon class="item-icon" theme="two-tone" size="64" :fill="['#333' ,'#0d52b3']" :strokeWidth="2" strokeLinecap="butt"/>
              <div class="item-content">
                <div class="flex-box item-content-title flex-start">
                  <span>应用程序开发</span>
                  <i class="triangle"></i>
                </div>
                <div class="item-content-info">安卓APP / IOS_APP / 桌面应用程序</div>
              </div>
            </div>
          </div>
        </div>
        <div id="instruction" class="company-instruction">
          <div class="company-instruction-content">
            <div class="tab-title font-black">ABOUT US</div>
            <div class="tab-desc">/&nbsp;&nbsp;公司简介&nbsp;&nbsp;/</div>
            <div class="flex-box">
              <div class="company-pic"></div>
              <div class="company-slogan">
                <div class="slogan-title">
                  <span>诺傲数科 . </span>
                  <span class="slogan-title-weight">企业数字化转型的践行者</span>
                </div>
                <div class="slogan-info">内蒙古诺傲数字科技有限公司（简称诺傲数科），成立于2020年，诺傲数字科技是一家以”互联网+”为核心的服务型公司，主要面向企业提供数字化解决方案，助力企业完成数字化转型。</div>
                <div class="slogan-info">我们的愿景：让更多的客户认识我们，让认识我们的客户认可我们。</div>
                <div class="slogan-info">我们的使命：走近客户，了解客户，服务客户。</div>
                <div class="slogan-info">我们的价值观：一诺千金，追求卓越。</div>
              </div>
            </div>
          </div>
        </div>
        <div id="software" class="software-products">
          <div class="software-products-content">
            <div class="tab-title">SOFTWARE PRODUCTS</div>
            <div class="tab-desc font-white">/&nbsp;&nbsp;软件产品&nbsp;&nbsp;/</div>
            <div class="software-induct flex-box">
              <div class="software-left">
                <div class="software-desc">
                  <span class="vertical-line"></span>
                  <div class="software-desc-title">矿场管理系统</div>
                  <div class="software-desc-detail">公司为内蒙某矿场开发的矿场管理系统，帮助企业实现了数字化转型，提高了公司管理和运行效率，提高了员工的办公效率，为企业带来了效益的提升。</div>
                </div>
                <div class="otr-software-name">
                  <play-one style="margin-right: 20px" theme="filled" size="16" fill="#f5f7fa"/>
                  电影票购票小程序
                </div>
                <div class="otr-software-name">
                  <play-one style="margin-right: 20px" theme="filled" size="16" fill="#f5f7fa"/>
                  企业人员管理APP
                </div>
                <div class="otr-software-name">
                  <play-one style="margin-right: 20px" theme="filled" size="16" fill="#f5f7fa"/>
                  劳务市场招聘小程序
                </div>
              </div>
              <div class="software-right">
                <div class="sw-back">
                  <img class="sw-back-img" src="../assets/images/software.jpeg" alt="app">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="case" class="typical-cases">
          <div class="tab-title">TYPICAL CASES</div>
          <div class="tab-desc">/&nbsp;&nbsp;成功案例&nbsp;&nbsp;/</div>
          <div class="typical-cases-content flex-box flex-around">
            <img class="case-item" src="../assets/images/case_1.png" alt="case1" />
            <img class="case-item" src="../assets/images/case_2.png" alt="case1" />
            <img class="case-item" src="../assets/images/case_3.png" alt="case1" />
          </div>
        </div>
        <div id="contact" class="contact-us">
          <div class="tab-title">CONTACT US</div>
          <div class="tab-desc font-white">/&nbsp;&nbsp;联系我们&nbsp;&nbsp;/</div>
          <div class="contact-us-content">
            <div>
              <div class="contact-cpy-name">诺傲数科</div>
              <div class="contact-cpy-en">NA TECHNOLOGY</div>
              <div class="contact-cpy-belong">版权所有@内蒙古诺傲数字科技有限公司版权所有</div>
            </div>
            <div class="contact-type">
              <div>18647370821<phone-telephone style="margin-left: 10px" theme="outline" size="14" fill="#f0f0f0" :strokeWidth="2" strokeLinecap="butt"/></div>
              <div>709456036@qq.com<mail-icon style="margin-left: 10px" theme="outline" size="14" fill="#f0f0f0" :strokeWidth="2" strokeLinecap="butt"/></div>
              <div>709456036<tencent-qq style="margin-left: 10px" theme="outline" size="14" fill="#f0f0f0" :strokeWidth="2" strokeLinecap="butt"/></div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-backtop :right="80" :bottom="80" />
  </div>
</template>

<script>
export default {
  name: 'home-page',
  props: {},
  components: {},
  data() {
    return {
      ellipsis: false,
      activeIndex: '1',
      menuList: [
        {label: '首页', key: '1', target: '#banner'},
        {label: '技术服务', key: '2', target: '#tecnology'},
        {label: '公司简介', key: '3', target: '#instruction'},
        {label: '软件中心', key: '4', target: '#software'},
        {label: '成功案例', key: '5', target: '#case'},
        {label: '联系我们', key: '6', target: '#contact'}
      ],
      bannerList: [
        {label: '1', key: '1', imgUrl: require('../assets/images/banner1.jpg')},
        {label: '2', key: '2', imgUrl: require('../assets/images/banner2.jpeg')},
        {label: '3', key: '3', imgUrl: require('../assets/images/banner3.jpeg')}
      ]
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .home {
    width: 100%;
    height: 100%;
  }
  .home .header-box {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .home .el-menu-item.is-active {
    border-bottom: none;
    color: #fff!important;
  }

  .home .el-menu--horizontal>.el-menu-item {
    border-bottom: none;
  }

  .home .img-logo {
    width: 40px;
    height: 40px;
  }

  .home .header-name {
    font-size: 24px;
    margin-left: 10px;
    margin-right: 40px;
  }

  .home .el-menu--horizontal {
    border-bottom: none;
  }

  .home .el-main {
    padding: 0;
  }

  .home .technology-service, .company-instruction {
    background-color: #f5f7fa;
    padding: 40px 0;
    margin: auto;
  }

  .home .company-instruction {
    padding-bottom: 80px;
  }

  .home .company-instruction-content {
    width: 65%;
    margin: 10px auto;
    padding: 40px 0px 80px 0;
    background: url('../assets/images/aboutUs.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 5px;
  }

  .home .company-pic {
    width: 50%;
    height: 300px;
    margin-left: -40px;
    background: url('../assets/images/company.png') no-repeat;
    background-size: 100% 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 40px;
  }

  .home .company-slogan {
    width: 50%;
    padding: 0 40px;
    color: #333;
    text-align: left;
    padding-top: 40px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .home .slogan-title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #f5f7fa;
  }

  .home .slogan-title-weight {
    color: #0d52b3;
  }

  .home .slogan-info {
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;
  }

  .home .service-content {
    width: 65%;
    padding: 20px 0;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .home .content-item {
    width: calc(50% - 5px);
    min-width: 500px;
    background-color: #fff;
    color: black;
    height: 150px;
    border-radius: 5px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 10px;
  }

  .home .item-icon {
    margin-right: 40px;
  }

  .home .item-content {
    width: 320px;
  }

  .home .item-content-title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f7fa;
  }

  .home .item-content-info {
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
  }

  .home .triangle {
    width: 0;
    height: 0;
    border: 6px solid #0d52b3;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    margin-left: 10px;
    margin-top: 9px;
  }

  .home .tab-title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .home .tab-desc {
    font-size: 16px;
    color: #0d52b3;
  }

  .home .software-products {
    background-color: #012150;
  }

  .home .software-products-content {
    width: 65%;
    padding: 40px 0;
    background: url('../assets/images/software.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 5px;
    margin: auto;
  }

  .home .software-induct {
    padding: 40px 0;
  }

  .home .software-left, .software-right {
    width: 45%;
  }

  .home .software-right {
    display: flex;
    justify-content: flex-end;
  }

  .home .software-desc {
    text-align: left;
    position: relative;
    border-radius: 5px;
    background-color: #001e4d;
    padding: 20px 40px;
  }

  .home .software-desc-title {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f7fa;
  }

  .home .software-desc-detail {
    font-size: 12px;
    color: #f5f7fa;
    padding-top: 20px;
    line-height: 30px;
  }

  .vertical-line {
    position: absolute;
    width: 3px;
    height: 100px;
    background-color: #0d52b3;
    top: 37px;
    left: 0;
  }

  .home .otr-software-name {
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
    line-height: 60px;
  }

  .home .sw-back {
    width: 240px;
    height: 400px;
    border: 1px solid #0d52b3;
    border-radius: 5px;
    position: relative;
  }

  .home .sw-back-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -10px;
    left: -10px;
  }

  .home .typical-cases {
    background: #fff;
    color: #333;
    padding: 40px 0;
  }

  .home .typical-cases-content {
    width: 65%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: auto;
  }

  .home .case-item {
    width: 300px;
    height: 200px;
    border-radius: 5px;
  }

  .home .contact-us {
    background-color: #14191f;
    color: #fff;
    padding: 40px 0;
  }

  .home .contact-us-content {
    width: 65%;
    padding: 40px 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .home .contact-cpy-name {
    font-size: 30px;
  }

  .home .contact-cpy-en {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .home .contact-cpy-belong {
    color: #f0f0f0;
    font-size: 12px;
    line-height: 20px;
  }

  .home .contact-type {
    line-height: 30px;
    text-align: right;
  }
  
</style>
