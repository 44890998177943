import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './global.css'
import '@icon-park/vue-next/styles/index.css'
import IconPark from './plugins/iconPark'

const app = createApp(App)

app.use(IconPark)
app.use(ElementPlus)
app.mount('#app')
