import {DataDisplay, NewComputer, WeixinMiniApp, Application, PlayOne, PhoneTelephone, Mail, TencentQq} from '@icon-park/vue-next'

export default {
  install(app) {
    app.component('DataDisplay', DataDisplay)
    app.component('NewComputer', NewComputer)
    app.component('WeixinMiniApp', WeixinMiniApp)
    app.component('appIcon', Application)
    app.component('PlayOne', PlayOne)
    app.component('PhoneTelephone', PhoneTelephone)
    app.component('MailIcon', Mail)
    app.component('TencentQq', TencentQq)
  }
}